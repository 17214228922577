import { render, staticRenderFns } from "./index.vue?vue&type=template&id=2c4af510&scoped=true&"
import script from "./index.vue?vue&type=script&lang=js&"
export * from "./index.vue?vue&type=script&lang=js&"
import style0 from "./index.vue?vue&type=style&index=0&id=2c4af510&prod&lang=postcss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2c4af510",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {Header: require('/app/components/Header.vue').default,GridPosts: require('/app/components/GridPosts.vue').default,Button: require('/app/components/Button.vue').default,BeAdvised: require('/app/components/BeAdvised.vue').default,FooterV2: require('/app/components/FooterV2.vue').default})
