// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/@nuxt/postcss8/node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../node_modules/@nuxt/postcss8/node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../../assets/img/arrow-right-red.svg");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".card-image[data-v-2c4af510]{aspect-ratio:402/256}.section-category[data-v-2c4af510]{margin-top:140px;}.section-category__container[data-v-2c4af510]{display:flex;flex-direction:column;justify-content:space-between;}@media (min-width: 768px){.section-category__container[data-v-2c4af510]{flex-direction:row;align-items:center}}.section-category__label[data-v-2c4af510]{--tw-text-opacity:1;color:rgb(57 7 63 / var(--tw-text-opacity));font-weight:900;font-size:8.5vw;line-height:110%}@media (min-width: 768px){.section-category__label[data-v-2c4af510]{font-size:32px}}@media (min-width: 1024px){.section-category__label[data-v-2c4af510]{font-size:46px}}.section-category__see-more[data-v-2c4af510]{--tw-text-opacity:1;color:rgb(231 51 49 / var(--tw-text-opacity));font-weight:700}.section-category__see-more a[data-v-2c4af510]:after{content:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");vertical-align:middle;margin-left:10px}.transi[data-v-2c4af510]{opacity:0;-webkit-animation:opacity-2c4af510 .5s ease-out forwards;animation:opacity-2c4af510 .5s ease-out forwards}@-webkit-keyframes opacity-2c4af510{to{opacity:1}}@keyframes opacity-2c4af510{to{opacity:1}}.surtitle[data-v-2c4af510]{margin-bottom:0}.arrow-glide[data-v-2c4af510]{--tw-bg-opacity:1;background-color:rgb(213 235 253 / var(--tw-bg-opacity));width:80px;aspect-ratio:1;display:grid;place-content:center;border-radius:50%;cursor:pointer}@media (min-width: 1536px){.arrow-glide[data-v-2c4af510]{position:absolute;top:50%}.arrow-glide.left[data-v-2c4af510]{left:0;transform:translateX(-120%)}.arrow-glide.right[data-v-2c4af510]{right:0;transform:translateX(120%)}}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
module.exports = ___CSS_LOADER_EXPORT___;
